import React, { useContext, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Layout } from "../layouts/Site";
import { Content } from "../components/Content";
import { AssertPage } from "../components/AssetContext";
import { HeaderPage, PageWidth, Section } from "../components/Containers";
import { Image } from "../components/Image";
import { Link, PageHeading, pageHeadingStyles, SectionHeading } from "../components/Headings";
import { Testimonials } from "../components/Testimonial";
import { Curve } from "../components/PageWidthElements";
import { RichText } from "../components/RichText";
import { faFacebookF, faLinkedinIn, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { join, sectionEndLine } from "../styles/styles";
import { WorkInProgress } from "../components/WorkInProgress";
import { Helmet } from "react-helmet";
import { Component } from "../components/Component";
import { Header } from "../components/Header";
import { CallToAction } from "../components/CallToAction";

const linkedInProfile = "https://linkedin.com/in/";
const twitterProfile = "https://twitter.com/";
const facebookProfile = "https://facebook.com/";
const instagramProfile = "https://instagram.com/";

export default function CareersPage({location}) {
  const { page, jobPostings, defaultMetadata } = useStaticQuery(query);

  return <Layout title={page.name}>
    <AssertPage id={page.contentful_id} url={location.pathname} />
    <Helmet>
      <meta name="description" content={page.metaDescription || defaultMetadata.metaDescription} />
    </Helmet>

    <PageWidth className={join(sectionEndLine, "pt-header md:pt-header-md")}>
      {page.contents && page.contents.map(createTopLevelComponent)}
    </PageWidth>

    <JobPostings list={jobPostings && jobPostings.nodes} selected={new URLSearchParams(location.search).get("selected")}/>

  </Layout>;
}

function createTopLevelComponent(content) {
  return <Section className="pt-10">
    <Component content={content} />
  </Section>;
}

function JobPostings({list, selected}) {
  const [selection, select] = useState(list ? list.find(jd => jd.title == selected) || list[0] : undefined);

  if (list) {
    return <PageWidth className="pt-10">
      <SectionHeading className="text-center">Job openings</SectionHeading>
      <Section className="flex flex-row">
      <ul className="basis-1/4">
        <JobDescriptionMenu list={list} selection={selection} select={select} />
      </ul>
      <div className="basis-3/4">
        <RichText body={selection.description} />
        <CallToAction action={selection.callToAction} />
      </div>
      </Section>
    </PageWidth>;
  }

  function JobDescriptionMenu({list, selection, select}) {
    return <> {list.map(jd => jd === selection ?
      <li className="font-bold p-2 mb-4 mr-4 text-page bg-secondary">{jd.title}</li> :
      <li className="p-2 mb-4 hover:font-bold" onClick={() => select(jd)}>{jd.title}</li>)} </>
  }

  return <Section className="pt-10 text-center">
    <SectionHeading>Job openings</SectionHeading>
    We have no vacancies at the moment, please come back later.</Section>
}

const query = graphql`{
  page: contentfulSiteContentList(key: {eq: "careers"}) {
    key
    name
    contentful_id
    contents {
      ... on ContentfulSiteContent {
        ...Content
      }
      ... on ContentfulSiteContentList {
        key
        name
        contents {
          ... on ContentfulSiteContent {
            ...Content
          }
          ... on ContentfulSiteTestimonial {
            ...Testimonial
          }
          ... on ContentfulSiteTeamMember {
            name
            description {
              raw
            }
            image {
              ...ImageContent
            }            
            linkedin
            twitter
            facebook
            instagram
          }
        }
      }
    }
  }
  jobPostings: allContentfulJobPosting(sort: {fields: updatedAt, order: DESC}) {
    nodes {
      title
      description {
        raw
      }
      callToAction {
        ...CallToAction
       }
    }
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  }
}`;